<template>
  <el-drawer :title='`${form.title||""}详情`' :visible.sync='visible' :close-on-press-escape='false'
             :wrapper-closable='false'
             custom-class='product-dress-audit-store-container'>
    <!--         拒绝说明-->
    <el-dialog title='拒绝理由' width='30%' :visible.sync='visibleRefuse' append-to-body>
      <el-form :model='refuseForm' :rules='rules' ref='refuseFormRef' label-width='100px'>
        <el-form-item label='理由' prop='refuse_reason'>
          <el-input v-model='refuseForm.refuse_reason'></el-input>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='visibleRefuse = false'>取 消</el-button>
        <el-button type='primary' @click='handleRefuse'>确 定</el-button>
      </div>
    </el-dialog>
    <el-form ref='form' :model='form' :rules='rules' label-width='110px' size='small'>
      <div style='display: flex;justify-content: space-between;'>
        <div style='width: calc(100% - 260px)'>
          <el-form-item label='品牌'>
            <el-input v-model='form.mini_brand_name' disabled />
          </el-form-item>
          <el-form-item label='可播平台' prop='platform_code'>
            <PlatformSelect v-model='form.platform_code' :default-code='form.platform_code' :range-all='true'
                            placeholder='请选择可播平台' @saved='handleSelectPlatform' />
          </el-form-item>
          <el-form-item label='产品名称' prop='title'>
            <el-input v-model='form.title' />
          </el-form-item>
          <el-form-item label='尺码/颜色' prop='spec_name'>
            <el-input v-model='form.spec_name' />
          </el-form-item>
          <el-form-item label='日常价' prop='price'>
            <el-input v-model='form.price' />
          </el-form-item>
          <el-form-item label='直播价' prop='lb_price'>
            <el-input v-model='form.lb_price' />
          </el-form-item>
        </div>
        <ml-upload-image :key='key' :id.sync='form.cover' :url.sync='form.cover_url' />
      </div>
      <el-form-item label='赠品'>
        <el-input v-model='form.gift' />
      </el-form-item>
      <el-form-item label='佣金' prop='commission'>
        <el-input v-model='form.commission' />
      </el-form-item>
      <el-form-item label='保价情况'>
        <el-input v-model='form.support_situation' />
      </el-form-item>
      <el-form-item label='寄样明细'>
        <el-input v-model='form.samples' />
      </el-form-item>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='店铺评分'>
            <el-input v-model='form.company_score' placeholder='店铺评分' clearable />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='对接人联系方式'>
            <el-input v-model='form.contact_info' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='预售天数'>
            <el-input v-model='form.wares_prep_day' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='预售库存'>
            <el-input v-model='form.wares_prep_qty' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='是否补货'>
            <el-radio-group v-model='form.wares_prep_back_is'>
              <el-radio-button label='1'>是</el-radio-button>
              <el-radio-button label='2'>否</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='补货周期'>
            <el-input v-model='form.wares_prep_back_cycle' />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span='12'>
          <el-form-item label='现货库存'>
            <el-input v-model='form.wares_exist_qty' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='是否有现货'>
            <el-input v-model='form.wares_exist_is' placeholder='预计直播时是否有现货' />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label='发货时效'>
        <el-input v-model='form.delivery_cycle' />
      </el-form-item>
      <el-form-item label='备注'>
        <el-input type='textarea' v-model='form.remark' />
      </el-form-item>
      <el-form-item label='负责人' prop='leader_id'>
        <el-input v-if='form.id&&!editLeader' v-model='form.leader_name' placeholder='负责人'
                  disabled style='width: 200px'>
          <template slot='append'>
            <el-button icon='el-icon-edit' @click='editLeader=true' />
          </template>
        </el-input>
        <UserSelect v-else :leader-id.sync='form.leader_id' :keyword.sync='form.leader_name' />
      </el-form-item>
    </el-form>
    <div v-if='form.status === 1' class='footer'>
      <ml-button v-if="hasAuthority('live-product-update')" size='medium' text='保存信息'
                 @click.native='handleStore("update")' />
      <ml-button v-if="hasAuthority('live-product-agree')" size='medium' text='通过并保存到库'
                 @click.native='handleStore("agree")' />
      <ml-button v-if="hasAuthority('live-product-refuse')" size='medium' text='审核不通过'
                 @click.native='visibleRefuse=true' />
    </div>
    <div v-if='form.status === 2' class='footer' style='justify-content: flex-end;padding-right: 20px'>
      <ml-button  type='warning' icon='el-icon-refresh-left' v-if="hasAuthority('live-product-retrial')" size='medium'
                 text='退回'
                 @click.native='handleRetrial' />
    </div>
  </el-drawer>
</template>
<script>
import MlUploadImage from '@/components/MlUploadImg/single.vue'
import MlButton from '@/components/MlButton/index.vue'
import UserSelect from '@/pages/lb/components/UserSelect.vue'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import { splitWaresString, jointWaresString } from '@/utils/business/dress'

const getKey = function() {
  return '' + (new Date().getTime())
}

const rules = {
  title: { required: true, message: '商品名称必填', trigger: 'blur' },
  spec_name: { required: true, message: '尺码/颜色必填', trigger: 'blur' },
  price: { required: true, message: '日常价必填', trigger: 'blur' },
  lb_price: { required: true, message: '直播价必填', trigger: 'blur' },
  leader_id: { required: true, message: '负责人', trigger: 'change' },
  platform_code: { required: true, message: '必填', trigger: ['change', 'blur'] },
  commission: { required: true, message: '必填', trigger: ['change', 'blur'] },
  refuse_reason: [{ required: true, message: '请输入拒绝理由' }]
}
const ModelForm = {
  id: null,
  cover: null,
  leader_id: null,
  brand_id: null,// 品牌
  mini_brand_name: null,// 品牌
  title: null,
  spec_name: null,
  price: null,// 日常价
  lb_price: null,// 直播价
  gift: null,
  commission: null,// 佣金
  support_situation: null,// 保价情况
  samples: null,// 寄样明细
  delivery_cycle: null,// 发货时效
  wares_prep: '',
  wares_exist: '',
  wares_prep_day: '',// 预售天数
  wares_prep_qty: '',// 预售库存
  wares_prep_back_is: '',// 是否补货
  wares_prep_back_cycle: '',// 补货周期
  wares_exist_is: '',// 是否现货
  wares_exist_qty: '',// 现货库存
  remark: null,
  platform_name: [],
  platform_code: []
}
export default {
  name: 'ProductDressAuditStore',
  components: { UserSelect, MlButton, MlUploadImage, PlatformSelect },
  data() {
    return {
      rules,
      key: getKey(),
      loading: false,
      visible: false,
      visibleRefuse: false,
      editLeader: false,
      form: { ...{}, ...ModelForm },
      refuseForm: { refuse_reason: '' }
    }
  },
  watch: {
    visibleRefuse(_) {
      if (_) {
        this.refuseForm = { refuse_reason: '' }
      }
    }
  },
  methods: {
    assembleForm: function() {
      let params = { ...{}, ...this.form }
      params = { ...params, ...jointWaresString(this.form) }
      this.form = params
    },
    callbackSuccess: function() {
      this.$message.success('操作成功')
      this.visible = false
      this.visibleRefuse = false
      this.$emit('success')
    },
    // 退回重审操作
    handleRetrial() {

      let msg = `确定要将【${this.form.title}】退回重审，商品信息为同步当前（服饰）产品库信息？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        await this.$api.productCheckRetrial({ id: this.form.id })
        // 发起网络请求
        this.form.status = 1
        await this.$msgbox.alert('退回成功')
        this.$emit('success')
        this.visible = false
      }).catch(() => {
        this.$notify.info('已取消操作')
      })
    },
    handleStore: function(cmd = 'update') {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          this.$message.warning('数据验证失败，请检查必填项数据！')
          return
        }
        this.assembleForm()
        let res
        if (cmd === 'update') {
          res = await this.$api.prodectCheckUpdate(this.form)
        } else {
          res = await this.$api.productCheckAgree(this.form)
        }
        if (res) {
          this.callbackSuccess()
        }
      })
    },
    handleRefuse: function() {
      this.$refs.refuseFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请输入拒绝理由')
        }
        this.refuseForm.id = this.form.id
        const res = await this.$api.productCheckRefuse(this.refuseForm)
        if (res) {
          this.callbackSuccess()
        }
      })
    },
    show: async function(id) {
      this.visible = true
      this.loading = true
      const { info } = await this.$api.getProductInfoById({ id })
      this.loading = false
      const {
        wares_prep_day,
        wares_prep_qty,
        wares_prep_back_is,
        wares_prep_back_cycle,
        wares_exist_is,
        wares_exist_qty
      } = splitWaresString(info)
      info.wares_prep_day = wares_prep_day
      info.wares_prep_qty = wares_prep_qty
      info.wares_prep_back_is = wares_prep_back_is
      info.wares_prep_back_cycle = wares_prep_back_cycle
      info.wares_exist_is = wares_exist_is
      info.wares_exist_qty = wares_exist_qty
      if (info?.brand_name === '') {
        info.contact_user = info?.merchant?.contact_user || ''
        info.contact_info = info?.merchant?.contact_number || ''
        info.delivery_info = info?.merchant?.delivery_info || ''
        info.customer_name = info?.mini_customer_name || ''
        info.brand_name = info?.mini_brand_name || ''
      }

      this.form = info
      this.key = getKey()
    },
    handleSelectPlatform(val) {
      this.form.platform_code = val ? val.code : []
      this.form.platform_name = val ? val.name : []
    }
  }
}
</script>
<style lang='scss'>
.product-dress-audit-store-container {
  width: 1000px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 40px 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .ml-upload-image {
    width: 240px;
    height: 240px;
  }

  .el-form {
    height: calc(100% - 66px);
    overflow-y: auto;

    .el-radio-group {
      width: 100%;

      .el-radio-button {
        width: 50%;

        span {
          width: 100%;
        }
      }
    }
  }
}
</style>
